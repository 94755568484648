import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { MetaStyles } from '@styles/Typography'

export const Caption = styled.div`
  ${MetaStyles}
  max-width: ${tokens.get('maxWidth.wide')};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing()};
  opacity: 0.6;
  text-align: ${styledMap('textAlign', {
    center: 'center',
    right: 'right',
    default: 'left',
  })};

  ${styledMap('align', {
    right: css`
      display: grid;
      grid-template-columns: ${styledMap('featuredStyle', {
        portrait: 'repeat(2, 1fr)',
        default: 'repeat(9, 1fr)',
      })};
      ${styledMap('featuredStyle', {
        portrait: css`
          grid-column-gap: ${spacing(2)};

          ${media.greaterThan('s')`
            grid-column-gap: ${spacing(4)};
          `}

          ${media.greaterThan('l')`
            grid-column-gap: ${spacing(6)};
          `}
        `,
        default: '0',
      })};
    `,
    default: css``,
  })};

  ${media.greaterThan('s')`
    margin-top: ${spacing(2)};
  `}

  ${media.greaterThan('m')`
    margin-top: ${spacing(3)};
  `}


  .caption-inner {
    ${media.greaterThan('s')`
      padding-right: ${spacing(3)};
      max-width: ${props => {
        if (!props.size) {
          return `${parseInt(tokens.get('maxWidth.wide'), 10) / 2}rem`
        }

        const [col, grid] = props.size.split('/')
        return `${(col / grid) * 100}%`
      }};
    `}

    ${styledMap('align', {
      right: css`
        grid-column: ${styledMap('featuredStyle', {
          portrait: '2 / -1;',
          default: '5 / -1;',
        })};
      `,
      default: css`
        margin-left: 0;
        margin-right: auto;
      `,
    })}
  }
`

export default ({
  content,
  align,
  textAlign = 'left',
  size,
  featuredStyle,
  imageCount,
}) => {
  return (
    <Caption
      className="caption"
      align={align}
      textAlign={textAlign}
      size={size}
      featuredStyle={featuredStyle}
      imageCount={imageCount}
    >
      <div
        className="caption-inner"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Caption>
  )
}
