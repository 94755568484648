//TODO https://medium.com/workbench/floating-parallax-images-with-react-and-framer-motion-2f3703a8a070
//https://codesandbox.io/s/framer-motion-parallax-osm5p?file=/src/index.tsx:0-1069
import React, { useEffect, useState } from 'react'
import { useMotionValue, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import _throttle from 'lodash.throttle'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { grayscale } from 'polished'

const getAlignment = (el) => {
  const dimensions = el.getBoundingClientRect()
  return window.innerWidth / 2 > dimensions.left + dimensions.width / 2
    ? 'left'
    : 'right'
}

export const MagicItem = ({ className, children, style, type = 'opacity' }) => {
  let initial
  let animate
  let threshold
  let transition
  let variants
  switch (type) {
    case 'opacity':
      threshold = [0.4]
      variants = {
        initial: {
          opacity: 0.5,
        },
        animate: {
          opacity: 1,
        },
      }
      transition = {
        duration: 0.2,
        ease: 'linear',
      }
      break
    case 'fadeUp':
      threshold = [0.2]
      variants = {
        initial: {
          y: 50,
          opacity: 0,
        },
        animate: {
          y: 0,
          opacity: 1,
        },
      }
      transition = {
        duration: 0.2,
        delay: 0.01 * Math.floor(Math.random() * Math.floor(20)),
        ease: 'easeOut',
      }
      break
    case 'textFade':
      threshold = [0.4]
      variants = {
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }
      transition = {
        duration: 0.05,
        ease: 'linear',
      }
      break
    default:
      break
  }

  const [animation, setAnimation] = useState('initial')

  const [ref, inView, entry] = useInView({
    threshold,
  })

  useEffect(() => {
    if (!entry) return

    // Scrolled InView
    if (animation === 'initial' && inView) {
      setAnimation('animate')
    }

    // // Scrolled up out of view
    if (!inView && entry.boundingClientRect.top > 0) {
      setAnimation('initial')
    }
  }, [inView])

  if (type === 'textFade') {
    const words = children.split(' ')
    const backWords = words.slice(-2).join(' ')
    const frontWords =
      words.length > 2 ? words.slice(0, -2).join(' ') + ' ' : ''
    const backText = backWords
      .replace(/<[^>]*>?/gm, '')
      .split('')
      .map((char, index) => {
        return (
          <motion.span key={index} transition={transition} variants={variants}>
            {char}
          </motion.span>
        )
      })

    return (
      <motion.div
        ref={ref}
        className={className}
        initial="initial"
        animate={animation}
        style={style}
        variants={{
          animate: {
            opacity: 1,
            transition: {
              duration: 0.4,
              when: 'beforeChildren',
              staggerChildren: 0.025,
            },
          },
          initial: {
            opacity: 0,
          },
        }}
      >
        {frontWords}
        {backText}
      </motion.div>
    )
  }

  return (
    <div className={className} ref={ref} style={style}>
      <motion.div
        initial="initial"
        animate={animation}
        variants={variants}
        transition={transition}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default MagicItem
