import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, H2 } from '@styles/Typography'
import Heading from '@elements/Heading'
import MediaItem from '@blocks/MediaItem'
import { ThemeContext } from '@layout/Layout'

export const HeadingWithMedia = styled.div`
  width: 100%;

  .heading {
    position: relative;
    z-index: ${tokens.z('aboveContent')};
    max-width: 75%;
    margin-bottom: -0.5em;

    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.base')};
    `}
  }

  .image,
  .video-container {
    ${box({
      spacing: 'x',
      multiplier: -0.5,
      type: 'margin',
    })};
    position: relative;

    ${media.greaterThan('l')`
      margin-left: ${spacing(-6)};
      margin-right: ${spacing(-6)};
    `}

    &::after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 50%;
      z-index: ${tokens.z('content')};
    }

    ${styledMap('gradient', {
      top: css`
        &::after {
          content: '';
          top: 0;
          background: linear-gradient(
            to bottom,
            ${(props) => {
              return tokens.color(`gradient.hero.${props.theme}.from`)
            }},
            ${(props) => {
              return tokens.color(`gradient.hero.${props.theme}.to`)
            }}
          );
        }
      `,
      bottom: css`
        &::after {
          content: '';
          bottom: 0;
          background: linear-gradient(
            to top,
            ${(props) => {
              return tokens.color(`gradient.hero.${props.theme}.from`)
            }},
            ${(props) => {
              return tokens.color(`gradient.hero.${props.theme}.to`)
            }}
          );
        }
      `,
      default: '',
    })};
  }
`

export default ({
  heading,
  headingSize = 'large',
  media,
  className,
  gradient,
  ...props
}) => {
  const { theme } = useContext(ThemeContext)

  return (
    <HeadingWithMedia className={className} gradient={gradient} theme={theme}>
      <Heading
        className="heading"
        heading={heading}
        headingSize={headingSize}
      />
      <MediaItem media={media} {...props} />
    </HeadingWithMedia>
  )
}
