import React from 'react'
import SEO from '@blocks/SEO'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import Hero from '@regions/Hero'
import HeroHome from '@regions/Hero/HeroHome'
import HeroMixPackImage from '@regions/Hero/HeroMixPackImage'

export const Container = styled.div``

export default ({ children, template, className = '' }) => {
  const {
    heroType = 'full',
    slug = '',
    meta,
    heroFields: { textContent, image, hasSocial, beers, gallery },
    featureImage,
    featureImageType,
    seo,
    ...data
  } = template
  const classes = `container ${slug} ${className}`

  return (
    <Container className={classes}>
      <SEO seo={seo} />
      {
        (heroType === 'home' && <HeroHome template={template} textContent={textContent} image={image} beers={beers} />) 
          || 
        (heroType === 'mixPack' && <HeroMixPackImage image={featureImage} featureImageType={featureImageType} />) 
          || 
        <Hero
          type={heroType}
          template={template}
          textContent={textContent}
          image={image}
          featureImageType={featureImageType}
          gallery={gallery}
          hasLogo
          hasSocial={hasSocial}
          meta={meta}
          {...data}
        />
      }
      {children}
    </Container>
  )
}
