import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

export const Video = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: ${(props) => {
      const [y, x] = props.ratio.split(':')
      return `${(x / y) * 100}%`
    }};
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
`

export default ({
  className = 'video-container',
  ratio = '16:9',
  videoUrl,
  videoControls = {
    loop: true,
    muted: true,
    playsInline: true,
    autoPlay: true,
    controlsList: 'nodownload',
    disablePictureInPicture: true,
  },
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '400px 0px 0px',
    triggerOnce: true,
  })
  const [isLoaded, setLoaded] = useState(false)
  const handleLoad = () => {
    if (!isLoaded) {
      setLoaded(true)
    }
  }
  return (
    <Video className={className} ratio={ratio} ref={ref}>
      {inView && (
        <motion.video
          {...videoControls}
          animate={isLoaded ? 'visible' : 'hidden'}
          variants={{
            visible: {
              opacity: 1,
              transition: {
                duration: 0.1,
              },
            },
            hidden: {
              opacity: 0,
            },
          }}
          onLoadedData={handleLoad}
        >
          <source type="video/mp4" src={videoUrl} />
        </motion.video>
      )}
    </Video>
  )
}
