import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import Image from '@elements/Image'
import { motion, useMotionValue, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { ParallaxItem } from '@hooks/useParallax'

export const HomeHeroBeers = styled.div`
  position: relative;
  z-index: ${tokens.z('aboveContent') + 1};
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
  transform-style: preserve-3d;

  ${media.lessThan('360px')`
    width: 9rem;
  `}

  ${media.greaterThan('s')`
    width: 17.5rem;
  `}

  .bottle {
    top: 0;
    bottom: 0;
    width: 100%;
  }
`

const LOOP_MAX = 32
const LOOP_SlOW_DOWN = 24

export default ({ beers: initialBeers, className }) => {
  if (initialBeers.length === 1) {
    return (
      <HomeHeroBeers className={className}>
        {initialBeers.map((beer, index) => {
          return (
            <div
              key={`beer-${index}`}
              className="bottle"
              style={{ position: 'relative' }}
            >
              <Image source={beer} />
            </div>
          )
        })}
      </HomeHeroBeers>
    )
  }

  const beers = [...initialBeers].reverse()
  const loopCount = useMotionValue(1)
  const isPlaying = useMotionValue(false)
  const durations = []
  const beerControls = beers.map(() => {
    return useAnimation()
  })
  const [ref, inView, entry] = useInView({
    threshold: 0.8,
  })

  const containerControls = useAnimation()

  //TODO Refactor
  const beerSequence = async (count) => {
    for (let i = 0; i < beerControls.length; i++) {
      const currentCount = loopCount.get()
      // const duration =
      //   currentCount > LOOP_SlOW_DOWN
      //     ? LOOP_MAX - currentCount < 3
      //       ? 0.0045 * currentCount
      //       : 0.0025 * currentCount
      //     : 0.0015 * currentCount
      let duration
      switch (true) {
        case LOOP_MAX - currentCount === 3:
          duration = 0.0065 * currentCount
          break;
        case LOOP_MAX - currentCount === 2:
          duration = 0.0085 * currentCount
          break;
        case LOOP_MAX - currentCount === 1:
          duration = 0.0105 * currentCount
          break;
        case LOOP_MAX - currentCount === 0:
          duration = 0.0125 * currentCount
          break;
        case currentCount > LOOP_SlOW_DOWN:
          duration = 0.0025 * currentCount
          break;
        default:
          duration = 0.0015 * currentCount
      }

      const delay = 0.01
      durations.push(delay)
      durations.push(duration)
      // eslint-disable-next-line no-await-in-loop
      if (i > 0) {
        await beerControls[i].start({
          opacity: 0,
          z: currentCount * 5,
          transition: {
            duration: 0,
          },
        })
      }
      // eslint-disable-next-line no-await-in-loop
      await beerControls[i].start({
        opacity: 1,
        transition: {
          delay,
          duration,
        },
      })
      if (i > 0) {
        await beerControls[i - 1].start({
          opacity: 0,
          transition: {
            delay,
            duration,
          },
        })
      }

      loopCount.set(currentCount + 1)
    }

    // eslint-disable-next-line no-use-before-define
    restartAnimation()
  }

  const restartAnimation = () => {
    if (loopCount.get() < LOOP_MAX) {
      beerSequence(loopCount.get())
    }
  }

  useEffect(() => {
    containerControls.set({
      opacity: 1,
    })
  }, [])

  useEffect(() => {
    if (inView === true && !isPlaying.get()) {
      isPlaying.set(true)
      beerSequence(loopCount.get())
    }
  }, [inView])

  return (
    <HomeHeroBeers ref={ref} className={className}>
      {beers.map((beer, index) => {
        return (
          <motion.div
            key={`beer-${index}`}
            className="bottle"
            style={{
              position: index === 0 ? 'relative' : 'absolute',
              opacity: index === 0 ? 1 : 0,
            }}
            animate={beerControls[index]}
          >
            <Image source={beer} loading="eager" />
          </motion.div>
        )
      })}
    </HomeHeroBeers>
  )
}
