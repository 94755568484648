import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { ComponentInnerBase } from '@mixins/MixinsComponent'

export const HeroInner = styled.div`
  ${ComponentInnerBase}
  position: relative;
  z-index: ${tokens.z('content')};
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  ${styledMap('isFullscreenImage', {
    true: css`
      height: 75vh;

      ${media.greaterThan('m')`
        height: calc(100vh - ${spacing(4)});
        min-height: 43.75rem;
      `}
    `,
    default: '',
  })};

  ${styledMap('type', {
    small: css`
      overflow: hidden;
    `,
    smallFull: css`
      overflow: hidden;
    `,
    default: '',
  })};
`

export default ({
  className,
  children,
  type,
  isFullscreenImage,
  size = 'wide',
}) => {
  return (
    <HeroInner
      className={className}
      type={type}
      isFullscreenImage={isFullscreenImage}
      size={size}
    >
      {children}
    </HeroInner>
  )
}
