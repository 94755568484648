import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'

export const HeroContentContainer = styled.div`
  ${styledMap('type', {
    singleBeer: css`
      ${box({
        spacing: 'bottom',
        multiplier: 0.5,
      })};
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      max-width: 25rem;

      ${media.greaterThan('s')`
        ${box({
          spacing: 'bottom',
        })};
         max-width: ${tokens.get('maxWidth.narrow')};
      `}

      ${media.greaterThan('l')`
        max-width: ${tokens.get('maxWidth.base')};
        padding-left: ${spacing(2)};
        padding-right: ${spacing(2)};

        @media (max-height: ${tokens.get('customBreaks.beerHeroHeight')}) {
          max-width: ${tokens.get('maxWidth.narrow')};
        }
      `}

      > .inner {
        position: relative;
      }
    `,
    post: css`
      ${box({
        spacing: 'x',
      })};
      > .inner {
        max-width: ${tokens.get('maxWidth.wide')};
        margin-left: auto;
        margin-right: auto;
      }
    `,
    default: css`
      width: 100%;
    `,
  })};
`

export default ({ children, type }) => {
  return (
    <HeroContentContainer type={type}>
      <div className="inner">{children}</div>
    </HeroContentContainer>
  )
}
