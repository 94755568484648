import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import uniqid from 'uniqid'
import { motion } from 'framer-motion'
import { ThemeContext } from '@layout/Layout'
import { useInView } from 'react-intersection-observer'

export const Illustration = styled.svg``

export default ({ image }) => {
  if (!image) return null
  const { supportsWebp } = useContext(ThemeContext)

  const { src, srcWebp } = image.localFile.childImageSharp.fluid
  const imageSrc = supportsWebp ? srcWebp : src
  const { height, width } = image.mediaDetails
  const [mediaID] = useState(uniqid('media-'))
  const [randomRotation] = useState(
    90 * Math.floor(Math.random() * Math.floor(4))
  )
  const [isVisible, setVisible] = useState('hidden')
  const [ref, inView, entry] = useInView({
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView && isVisible === 'hidden') {
      setVisible('visible')
    }

    if (
      !inView &&
      isVisible === 'visible' &&
      entry.boundingClientRect.top > 0
    ) {
      setVisible('hidden')
    }
  }, [inView])

  return (
    <Illustration
      height="100%"
      width="100%"
      viewBox={`0 0 ${width} ${height}`}
      ref={ref}
    >
      <defs>
        <mask
          id={`masking-${mediaID}`}
          maskContentUnits="objectBoundingBox"
          maskUnits="objectBoundingBox"
        >
          <path
            fill="#000"
            className="line-mask"
            d="M0 0h1v1H0z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
          <motion.path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth=".4"
            initial="hidden"
            animate={isVisible}
            style={{ originX: '0.5px', originY: '0.5px' }}
            variants={{
              hidden: {
                pathLength: 0,
                rotate: `${randomRotation}deg`,
              },
              visible: {
                pathLength: 1,
                rotate: `${randomRotation}deg`,
                transition: {
                  duration: 1.5,
                  ease: 'easeIn',
                },
              },
            }}
            d="M-0.035 -0.038L1.013 1.013 0 1 1.013 0.561 -0.067 0.487 1.129 -0.038 0.034 0.806 1 0.353 0.328 -0.038"
          />
        </mask>
      </defs>
      <image
        mask={`url(#masking-${mediaID})`}
        href={imageSrc}
        height="100%"
        width="100%"
      />
    </Illustration>
  )
}
