import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ComponentContent from '@blocks/ComponentContent'
import Social from '@blocks/Social'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import styledMap from 'styled-map'
import { ComponentBase } from '@mixins/MixinsComponent'

export const Text = styled.section`
  ${ComponentBase}
  width: 100%;

  > .inner {
    position: relative;

    ${styledMap('hasSocial', {
      true: css`
        max-width: ${tokens.get('maxWidth.wide')};
      `,
      default: '',
    })};
  }

  .social {
    ${box({
      spacing: 'top',
      multiplier: -1,
      type: 'margin',
    })};
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
      type: 'margin',
    })};
    padding-top: ${spacing(2)};
    display: flex;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('l')`
      position: absolute;
      left: 0;
      top: 0;
      z-index: ${tokens.z('aboveContent')};
      padding-top: unset;
      margin-top: unset;
      margin-bottom: unset;
    `}
  }
`

export default ({
  contentAlign,
  textAlign,
  textContent,
  className = '',
  isIntro,
  hasSocial,
  designations,
  isFlex,
  containerWidth = 'wide',
  typeSize = 'normal',
}) => {
  return (
    <Text
      className={className}
      hasSocial={hasSocial}
      designations={designations}
      isIntro={isIntro}
      isFlex={isFlex}
      size={containerWidth}
    >
      <div className="inner">
        {hasSocial && <Social className="social" layout="vert" isShare />}
        <ComponentContent
          textContent={textContent}
          size={hasSocial ? 'xwide' : ''}
          isIntro={typeSize === 'large' || isIntro}
          textAlign={textAlign}
          contentAlign={contentAlign}
        />
      </div>
    </Text>
  )
}
