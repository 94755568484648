import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { LargeTypeSizes } from '@styles/Typography'
import { TextContent } from '@elements/TextContent'
import { ThemeContext } from '@layout/Layout'

export const HeroContent = styled(TextContent)`
  margin-top: ${spacing(3)};
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};
`

export default ({ content, type, theme: themeOverride, className }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <HeroContent
      className={className}
      type={type}
      textSize="large"
      theme={themeOverride || theme}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
