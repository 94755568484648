import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import Image from '@elements/Image'
import Video from '@elements/Video'
import Illustration from '@elements/Illustration'
import uniqid from 'uniqid'
import { motion } from 'framer-motion'
import { MagicItem } from '@hooks/useMagic'
import { useInView } from 'react-intersection-observer'

export const MediaItem = styled.div`
  img {
    ${styledMap('mediaType', {
      illustration: css`
        ${styledMap('theme', {
          dark: css`
            filter: invert(1);
          `,
          default: '',
        })};
      `,
      default: '',
    })};
  }
`

export default ({
  className,
  media: { mediaType, image, videoUrl, ratio },
  magicType,
  ...props
}) => {
  const { theme } = useContext(ThemeContext)
  if (
    (!image && ['illustration', 'image'].includes(mediaType)) ||
    (!videoUrl && ['video'].includes(mediaType))
  ) {
    return null
  }

  return (
    <MediaItem className={className} mediaType={mediaType} theme={theme}>
      {
        {
          illustration: <Illustration image={image} {...props} />,
          image: (
            <MagicItem type={magicType}>
              <Image source={image} {...props} />
            </MagicItem>
          ),
          video: (
            <MagicItem type={magicType}>
              <Video videoUrl={videoUrl} ratio={ratio} {...props} />
            </MagicItem>
          ),
          none: null,
        }[mediaType || 'none']
      }
    </MediaItem>
  )
}
