import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
  useContext,
} from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { motion } from 'framer-motion'
import Image from '@elements/Image'
import { ThemeContext } from '@layout/Layout'
import ParallaxItem from '@hooks/useParallax'
import NavTriggerWaypoint from '@elements/NavTriggerWaypoint'
import { HeroContext } from './Hero'

export const HeroImage = styled(motion.div)`
  position: absolute;
  z-index: ${tokens.z('base')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  max-height: 42rem;

  @media (orientation: landscape) {
    height: 42rem;
  }

  ${media.greaterThan('s')`
    height: calc(100vh - ${spacing()});
    max-height: 64rem;

    @media (orientation: landscape) {
      height: 70rem;
    }
  `}

  ${media.greaterThan('l')`
    height: calc(100% - ${spacing(2)});
    max-height: none;
  `}

  .gatsby-image-wrapper {
    height: 100%;
  }

  .image {
    position: absolute;
    z-index: ${tokens.z('base')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${tokens.color('text')};

    ${media.greaterThan('s')`
      top: ${spacing()};
      left: ${spacing()};
      right: ${spacing()};
    `}

    ${media.greaterThan('l')`
      top: ${spacing(2)};
      left: ${spacing(2)};
      right: ${spacing(2)};
    `}
  }
`

export default ({ className, image, type }) => {
  const { theme } = useContext(ThemeContext)
  const { setHeroImageLoaded } = useContext(HeroContext)
  const [distance, setDistance] = useState(false)
  const ref = useRef()

  useLayoutEffect(() => {
    const { top, bottom } = ref.current.getBoundingClientRect()
    const height = top - bottom
    setDistance([0, Math.abs(height)])
  }, [])

  const handleOnLoad = () => {
    setHeroImageLoaded(true)
  }

  return (
    <HeroImage className={className} type={type} theme={theme} ref={ref}>
      <ParallaxItem
        config={[
          {
            prop: 'y',
            fromTo: ['0%', '25%'],
            distance,
          },
        ]}
        style={{ height: '100%' }}
      >
        <Image source={image} objectPosition="50% 100%" onLoad={handleOnLoad} />
      </ParallaxItem>
      {type === 'singleBeer' && (
        <NavTriggerWaypoint className="waypoint" style={{ bottom: 0 }} />
      )}
    </HeroImage>
  )
}
