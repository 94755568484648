import React, { useContext } from 'react'
import camelCase from 'lodash/camelCase'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { BlogH1Sizes } from '@styles/Typography'
import Image from '@elements/Image'
import { ReactComponent as ArrowsCircle } from '@icons/arrows-circle.svg'
import Modal from '@components/Modal'
import Slider from '@components/Slider'
import { ThemeContext } from '@layout/Layout'
import MediaItem from '@blocks/MediaItem'

export const HeroBottleImage = styled.div`
  ${BlogH1Sizes}
  position: relative;
  z-index: ${tokens.z('aboveContent') + 1};
  width: 9rem;
  margin-left: auto;
  margin-right: auto;
  width: ${styledMap('featureImageType', {
    'large-can': '12rem',
    'small-can': '15rem',
    default: '9rem',
  })};
  margin-top: ${styledMap('featureImageType', {
    'large-can': '0.16666em',
    'small-can': '0.16666em',
    default: 0,
  })};

  ${media.greaterThan('s')`
    width: ${styledMap('featureImageType', {
      'large-can': '23rem',
      'small-can': '25rem',
      default: '16rem',
    })};
  `}

  ${media.greaterThan('l')`
    position: absolute;
    right: 100%;
    width: 16rem;
    transform: translateX(50%);
    margin-top: 0;
    top: ${styledMap('featureImageType', {
      'large-can': spacing(-6),
      'small-can': spacing(8),
      default: spacing(-10),
    })};
    width: ${styledMap('featureImageType', {
      'large-can': '22rem',
      'small-can': '23rem',
      default: '16rem',
    })};
    margin-right: ${styledMap('featureImageType', {
      'large-can': spacing(10),
      'small-can': spacing(8),
      default: '0',
    })};
  `}

  .image {
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }

  .image {
    position: relative;
    z-index: ${tokens.z('content')};

    &:not(:last-child) {
      margin-bottom: ${styledMap('featureImageType', {
        'large-can': spacing(-6.5),
        'small-can': spacing(-7),
        default: spacing(-5),
      })};

      ${media.greaterThan('s')`
        margin-bottom: ${styledMap('featureImageType', {
          'large-can': spacing(-9.5),
          'small-can': spacing(-10),
          default: spacing(-7),
        })};
      `}
    }
  }

  .circle-arrows {
    height: ${spacing(6.5)};
    width: ${spacing(6.5)};
    margin-bottom: ${spacing(2)};

    ${media.greaterThan('s')`
      height: ${spacing(8)};
      width: ${spacing(8)};
    `}
  }

  a,
  button {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-family: ${tokens.get('type.fontFamilyBase')};
    font-size: 1rem;
    font-weight: ${tokens.get('type.fontWeight.normal')};
    line-height: 1;
    letter-spacing: 0;
  }

  .modal-link {
    cursor: zoom-in;
  }
`

export default ({ featureImageType, gallery, ...beer }) => {
  const { theme } = useContext(ThemeContext)
  const beerImage = beer[`${camelCase(featureImageType)}Image`]
  return (
    <HeroBottleImage theme={theme} featureImageType={featureImageType}>
      <Image source={beerImage} />
      {gallery && (
        <Modal
          className="modal-link"
          buttonLabel="View Artwork"
          isGallery
          size={gallery.length > 1 ? 'wide' : 'vast'}
          isSlider={gallery.length > 1}
        >
          {gallery.length > 1 ? (
            <Slider theme="dark" isFlex="collapse" sliderImages={gallery} />
          ) : (
            <MediaItem
              media={{ image: gallery[0], mediaType: 'image' }}
              objectFit="contain"
            />
          )}
        </Modal>
      )}
    </HeroBottleImage>
  )
}
