import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { MetaStyles } from '@styles/Typography'
import { ListBase, ListItemBase } from '@mixins/MixinsList'
import { PageContentRTE } from '@mixins/MixinsPage'
import { ThemeContext } from '@layout/Layout'
import MediaItem from '@blocks/MediaItem'
import Link from '@elements/Link'
import Arrow from '@svgs/Arrow'

export const HeroMeta = styled.div`
  ${PageContentRTE}
  ${box({
    spacing: 'x',
  })}
  ${box({
    spacing: 'top',
    multiplier: 0.5,
    type: 'margin',
  })}
  ${box({
    spacing: 'bottom',
    multiplier: 1,
    type: 'margin',
  })}

  .meta-inner {
    position: relative;
    max-width: ${tokens.get('maxWidth.wide')};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    ${media.greaterThan('m')`
      flex-flow: row nowrap;
    `}
  }

  .meta-data {
    flex: 1 1 100%;
    margin-left: 0;

    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.tiny')};
    `}
  }

  .meta-image {
    display: none;

    ${media.greaterThan('s')`
      ${box({
        spacing: 'top',
        multiplier: -0.5,
        type: 'margin',
      })}
      position: absolute;
      right: 0;
      top: 2rem;
      display: block;
      height: 8.5rem;

      ${styledMap('theme', {
        dark: css`
          filter: invert(1);
        `,
        default: '',
      })};
    `}
  }

  .author,
  .date,
  .category-list-item {
    display: block;
    line-height: ${tokens.get('type.lineHeight.list')};

    ${styledMap('isEvent', {
      false: MetaStyles,
      default: '',
    })};

    color: ${styledMap('isEvent', {
      true: styledMap('theme', {
        dark: tokens.color('text', 'light'),
        default: tokens.color('text'),
      }),
      default: styledMap('theme', {
        dark: tokens.color('text', 'darkFainter'),
        default: tokens.color('text', 'lightFainter'),
      }),
    })};
  }

  .author,
  .date {
    margin-bottom: ${spacing(2)};
  }

  .categories {
    ${ListBase}
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-bottom;
    margin-top: 0;
  }

  .category-list-item {
    display: inline-flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-bottom;
    margin-right: ${spacing(0.5)};
    margin-top: 0;

    &::before {
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .event-details {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;

    > li {
      ${ListItemBase}
    }
  }

  .event-ticket-link {
    order: 2;
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(1)};
    text-decoration: none;

    &:hover {
      color: ${tokens.color('text')};
    }

    &::before {
      display: none;
    }

    ${media.greaterThan('m')`
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
    `}

    .arrow {
      transform: rotate(-45deg);
      height: ${spacing(2.5)};
      width: ${spacing(2.5)};
    }
  }
`

const EventMeta = ({
  event: { eventDateDescription, eventLocation, eventDetails },
  categories,
}) => {
  const eventAddress =
    eventLocation && eventLocation.streetAddress
      ? eventLocation.streetAddress
      : ''
  const addressUrl = `https://www.google.com/maps/search/${encodeURI(
    eventAddress
  )}`

  if (!eventDetails && !eventDateDescription) {
    return null
  }

  return (
    <ul className="event-details">
      <li>{eventDateDescription}</li>
      <li>
        <a href={addressUrl} target="_blank" rel="noopener noreferrer">
          {eventAddress}
        </a>
      </li>
      {eventDetails &&
        eventDetails.map(({ detailType: type, link, text }) => {
          return (
            <li>
              {type === 'text' ? (
                text
              ) : (
                <Link
                  link={{
                    ...link,
                    type: 'url',
                  }}
                  linkStyle="none"
                />
              )}
            </li>
          )
        })}
      {categories && (
        <li>
          <Categories categories={categories} />
        </li>
      )}
    </ul>
  )
}

const Categories = ({ categories }) => {
  if (!categories) return null
  return (
    <ul className="categories">
      <li key="label" className="category-label category-list-item">
        Categories:
      </li>
      {categories.map(({ name, slug, databaseId, wpParent }, index) => {
        const parentId = wpParent ? `&parentId=${wpParent?.databaseId}` : ''
        const url = `/events-stories/?targetId=${databaseId}${parentId}`
        return (
          <li className="category-list-item" key={index}>
            <Link link={{ url }} linkStyle="none" className="category">
              {name}
            </Link>
            {index + 1 !== categories.length ? ', ' : ''}
          </li>
        )
      })}
    </ul>
  )
}

export default ({
  meta: { date, author, image, categories, eventTicketsLink, ...event },
  isEvent,
  theme: themeOverride,
}) => {
  const { theme } = useContext(ThemeContext)

  return (
    <HeroMeta isEvent={isEvent} theme={themeOverride || theme}>
      <div className="meta-inner">
        {isEvent && eventTicketsLink && (
          <Link
            className="event-ticket-link"
            link={{ ...eventTicketsLink, type: 'url' }}
          >
            {eventTicketsLink.title}
            <Arrow className="arrow" />
          </Link>
        )}
        <div className="meta-data">
          {isEvent ? (
            <EventMeta event={event} categories={categories} />
          ) : (
            <>
              {date && <time className="date">{date}</time>}
              {author && <span className="author">By: {author}</span>}
              {categories && <Categories categories={categories} />}
            </>
          )}
        </div>
        {image && !eventTicketsLink && (
          <MediaItem
            className="meta-image"
            media={{
              mediaType: 'illustration',
              image,
            }}
          />
        )}
      </div>
    </HeroMeta>
  )
}
