import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import Noise from '@images/noise.png'
import Logo from '@blocks/Logo'
import Image from '@elements/Image'
import NavTriggerWaypoint from '@elements/NavTriggerWaypoint'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { ParallaxItem } from '@hooks/useParallax'
import HeroHeading from './HeroHeading'
import HeroContent from './HeroContent'
import HeroHomeBeers from './HeroHomeBeers'

export const HeroHome = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  color: ${styledMap('theme', {
    light: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${media.greaterThan('s')`
    padding: ${spacing()} ${spacing()} 0;
  `}

  ${media.greaterThan('l')`
    padding: ${spacing(2)} ${spacing(2)} 0;
  `}


  > .inner {
    ${box({
      spacing: 'x',
    })}
    ${box({
      spacing: 'top',
    })};
    padding-bottom: 7.8%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    clip-path: polygon(0 0%, 100% 0%, 100% calc(92.2% + ${spacing(4)}), 0 100%);
    overflow: hidden;
  }

  .heading {
    position: relative;
    z-index: ${tokens.z('aboveContent')};
    text-align: center;
    max-width: 5.25em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -0.7em;
  }

  .noise {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${Noise}) center center repeat;
    z-index: ${tokens.z('base')};
    will-change: opacity;
  }

  .background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: ${tokens.z('base')};
    width: auto;
    max-width: ${tokens.get('maxWidth.vast')};
    margin-left: auto;
    margin-right: auto;
    background-color: ${tokens.color('home.hero')};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        rgba(255, 255, 255, 0.17),
        rgba(0, 0, 0, 0.15)
      );
      z-index: ${tokens.z('content')};
    }

    .image {
      ${getBoxSpacingForProperty('y', {
        property: 'top',
        multiplier: 1,
      })};
      position: relative;
      margin-top: ${spacing(12)};

      ${media.greaterThan('s')`
        margin-top: ${spacing(14)};
      `}

      ${media.lessThan('s')`

        width: 125%;
        transform: translateX(-12.5%);
      `}
    }
  }

  .content {
    ${box({
      spacing: 'y',
      type: 'margin',
    })};
    position: relative;
    z-index: ${tokens.z('aboveContent')};
  }

  .content-container {
    position: relative;
    width: 100%;
  }

  .waypoint {
    bottom: calc(6% + ${spacing(8)});
  }
`

export default ({
  template: { designations, title },
  image,
  textContent: {
    selectedFields,
    heading: heroHeading,
    content: heroContent,
    link: heroLink,
  },
  beers: initalBeers,
  type,
  className = 'hero',
  theme,
}) => {
  const Breakpoint = useBreakpoint()
  const controls = useAnimation()
  const ref = useRef()
  const [distance, setDistance] = useState(false)
  const beers =
    Breakpoint && Breakpoint.current === 'xs' ? [initalBeers[0]] : initalBeers

  useEffect(() => {
    controls.set({
      opacity: 0,
    })
  }, [])

  useLayoutEffect(() => {
    const { top, bottom } = ref.current.getBoundingClientRect()
    const height = top - bottom
    setDistance([0, Math.abs(height)])
  }, [])

  return (
    <HeroHome
      className={className}
      theme={theme}
      type={type}
      designations={designations}
      ref={ref}
    >
      <div className="inner">
        <Logo isHidden isFramed />
        <div className="content-container">
          {distance && (
            <ParallaxItem
              config={[
                {
                  prop: 'y',
                  fromTo: ['0%', '200%'],
                  distance,
                },
              ]}
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <HeroHeading
                heading={heroHeading || title}
                type="home"
                theme="light"
                className="heading"
              />
            </ParallaxItem>
          )}

          <HeroHomeBeers beers={beers} className="beers" />
          {heroContent &&
            selectedFields &&
            selectedFields.includes('content') && (
              <HeroContent
                theme="dark"
                content={heroContent}
                className="content"
              />
            )}
        </div>
        {distance && (
          <ParallaxItem
            config={[
              {
                prop: 'y',
                fromTo: ['0%', '40%'],
                distance,
              },
            ]}
            style={{
              top: 0,
              position: 'absolute',
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <div className="background">
              <Image source={image} />
            </div>
            <div className="noise" />
          </ParallaxItem>
        )}
        <NavTriggerWaypoint className="waypoint" />
      </div>
    </HeroHome>
  )
}
