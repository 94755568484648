import React, { useState, useLayoutEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import {
  HomeH1Sizes,
  BlogH1Sizes,
  FontStyleH1,
  H1Sizes,
  H1SizesShort,
} from '@styles/Typography'
import { ThemeContext } from '@layout/Layout'
import { HeroContext } from './Hero'
import ParallaxItem from '@hooks/useParallax'
import MagicItem from '@hooks/useMagic'

const smallHeading = css`
  ${H1SizesShort};
  position: relative;
  padding-top: ${spacing()};
  font-family: ${tokens.get('type.fontFamilyBase')};
  width: 100%;

  &::before {
    content: '';
    border-top: 1px solid
      ${styledMap('theme', {
        dark: tokens.color('white', '03'),
        default: tokens.color('black', '03'),
      })};
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: -100%;
  }
`

export const HeroHeading = styled.h1`
  position: relative;
  z-index: ${tokens.z('aboveContent')};
  width: 100%;
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${styledMap('type', {
    small: css`
      ${smallHeading}
      max-width: ${tokens.get('maxWidth.narrow')};
      margin-left: auto;
      margin-right: auto;
    `,
    smallFull: css`
      ${smallHeading}
      max-width: ${tokens.get('maxWidth.wide')};
    `,
    singleBeer: css`
      ${FontStyleH1}
      margin-bottom: -0.75em !important;
      color: ${(props) => props.headingColor || tokens.brand('light')};

      ${media.greaterThan('l')`
        margin-bottom: 0;
      `};
    `,
    post: styledMap('fullscreen', {
      true: css`
        ${H1Sizes}
        ${FontStyleH1}
        margin-top: -1.4em;
        max-width: 20rem;
        margin-left: 0;
        margin-right: auto;

        ${media.greaterThan('s')`
          max-width: ${tokens.get('maxWidth.narrow')};
        `}
      `,
      default: css`
        ${BlogH1Sizes}
        ${box({
          spacing: 'bottom',
          multiplier: 0.5,
          type: 'margin',
        })};
      `,
    }),
    home: css`
      ${HomeH1Sizes}
      ${box({
        spacing: 'bottom',
        multiplier: 0.5,
        type: 'margin',
      })};
      color: ${tokens.brand('light')};
    `,
    default: '',
  })};

  [class*='10-good-reasons'] & {
    max-width: ${tokens.get('maxWidth.tiny')};
    margin-left: auto;
    margin-right: auto;
  }
`

export default ({ heading, type, fullscreen, className }) => {
  if (!heading) return null
  const { theme, headingColor } = useContext(ThemeContext)

  if (type !== 'singleBeer') {
    return (
      <HeroHeading
        className={className}
        type={type}
        theme={theme}
        fullscreen={fullscreen}
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      />
    )
  }

  const [distance, setDistance] = useState(false)
  const { isHeroImageLoaded } = useContext(HeroContext)

  useLayoutEffect(() => {
    setDistance([0, window.innerHeight / 1.25])
  }, [])

  return (
    <HeroHeading
      className={className}
      headingColor={headingColor}
      type={type}
      theme={theme}
      fullscreen={fullscreen}
    >
      {distance && (
        <ParallaxItem
          config={[
            {
              prop: 'y',
              fromTo: ['0%', '30%'],
              distance,
            },
          ]}
          style={{ height: '100%' }}
        >
          <MagicItem
            style={{ opacity: isHeroImageLoaded ? 1 : 0 }}
            type={isHeroImageLoaded ? 'textFade' : 'none'}
          >
            {heading}
          </MagicItem>
        </ParallaxItem>
      )}
    </HeroHeading>
  )
}
