import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, box, spacing } from '@tokens'
import Text from '@components/Text'
import HeadingWithMedia from '@blocks/HeadingWithMedia'
import Logo from '@blocks/Logo'
import ParallaxItem from '@hooks/useParallax'
import MediaItem from '@blocks/MediaItem'
import ComponentContainer from '@layout/ComponentContainer'
import { motion, useAnimation } from 'framer-motion'
import HeroInner from './HeroInner'
import HeroContentContainer from './HeroContentContainer'
import HeroHeading from './HeroHeading'
import HeroContent from './HeroContent'
import HeroMeta from './HeroMeta'
import HeroImage from './HeroImage'
import HeroBottleImage from './HeroBottleImage'

export const HeroContext = React.createContext()

export const Hero = styled.div`
  ${box({
    spacing: 'x',
  })}
  ${box({
    spacing: 'top',
  })};
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: ${styledMap('theme', {
    light: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${styledMap('fullscreen', {
    true: css`
      ${styledMap('type', {
        post: css`
          min-height: 75vh;
          max-height: 30rem;
        `,
        default: '',
      })};

      ${media.greaterThan('l')`
          min-height: 100vh;

          @media (min-height: 900px) {
            min-height: 0;
            height: 100vh;
            max-height: 55.5rem;
          }
      `}
    `,
    default: css`
      height: auto;
    `,
  })};
`

export default ({
  template: { designations = [], title, slug },
  image,
  textContent: {
    selectedFields,
    heading: heroHeading,
    content: heroContent,
    link: heroLink,
  },
  hasSocial = true,
  type,
  meta,
  className: classNameOrg = 'hero',
  hasLogo,
  gallery,
  ...data
}) => {
  const [isHeroImageLoaded, setHeroImageLoaded] = useState(false)
  const hasHeroImage = !!image
  const isEvent = designations.includes('isEvent')
  const isFullscreenImage = ['singleBeer'].includes(type) && hasHeroImage
  const className = isFullscreenImage ? `${slug} ${classNameOrg}` : classNameOrg

  return (
    <HeroContext.Provider value={{ isHeroImageLoaded, setHeroImageLoaded }}>
      <Hero
        className={className}
        type={type}
        designations={designations}
        hasHeroImage={hasHeroImage}
        fullscreen={isFullscreenImage}
      >
        {isFullscreenImage && <HeroImage type={type} image={image} />}
        <HeroInner fullscreen={isFullscreenImage} type={type}>
          {hasLogo && <Logo isHidden isFramed={isFullscreenImage} />}
          {type !== 'post' && (
            <HeroContentContainer type={type}>
              {type !== false && (
                <>
                  <HeroHeading
                    heading={heroHeading || title}
                    type={type}
                    fullscreen={isFullscreenImage}
                  />
                </>
              )}
              {isFullscreenImage && (
                <HeroBottleImage gallery={gallery} {...data} />
              )}
              {heroContent &&
                selectedFields &&
                selectedFields.includes('content') && (
                  <HeroContent type={type} content={heroContent} />
                )}
            </HeroContentContainer>
          )}
        </HeroInner>
        {!isFullscreenImage && image && type !== 'post' && (
          <ComponentContainer isFlex size="wide">
            <MediaItem media={{ mediaType: 'image', image }} />
          </ComponentContainer>
        )}
      </Hero>
      {type === 'post' && (
        <>
          <HeroContentContainer type={type}>
            {hasHeroImage ? (
              <HeadingWithMedia
                heading={heroHeading || title}
                media={{ mediaType: 'image', image }}
                gradient="top"
              />
            ) : (
              <HeroHeading
                heading={heroHeading || title}
                type={type}
                fullscreen={isFullscreenImage}
              />
            )}
          </HeroContentContainer>
          {meta && (
            <HeroMeta
              meta={meta}
              designations={designations}
              isEvent={isEvent}
            />
          )}
          <Text
            hasSocial={hasSocial}
            isIntro
            contentAlign="center"
            textContent={{
              selectedFields,
              content: heroContent,
              link: heroLink,
            }}
            isFlex
            size="xwide"
          />
        </>
      )}
    </HeroContext.Provider>
  )
}
