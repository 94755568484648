import React, {
  useRef,
  useLayoutEffect,
  useState,
  useContext,
} from 'react'
import styled, {css} from 'styled-components'
import styledMap from 'styled-map'
import { tokens, box, media } from '@tokens'
import { motion } from 'framer-motion'
import Image from '@elements/Image'
import { ThemeContext } from '@layout/Layout'
import ParallaxItem from '@hooks/useParallax'


export const HeroMixPackImage = styled(motion.div)`
  width: 100%;
  ${box({
    spacing: 'x',
  })}
  ${box({
    spacing: 'y',
    type: 'margin',
  })};  
  ${box({
    spacing: 'top',
  })};
  
  ${media.greaterThan('s')`
    ${box({
        spacing: 'bottom',
    })};
  `}

  .inner {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${styledMap('size', {
      narrow: css`
        max-width: ${tokens.get('maxWidth.narrow')};
      `,
      base: css`
        max-width: ${tokens.get('maxWidth.base')};
      `,
      wide: css`
        max-width: ${tokens.get('maxWidth.wide')};
      `,
      xwide: css`
        max-width: ${tokens.get('maxWidth.xwide')};
      `,
      full: css`
        max-width: none ;
      `,
      default: css`
        max-width: ${tokens.get('maxWidth.xwide')};
      `,
    })};
  }
`

export default ({ className, image, featureImageType }) => {
  const { theme } = useContext(ThemeContext)
  const [distance, setDistance] = useState(false)
  const ref = useRef()
  const size = {
    '12-small-can': 'xwide',
    '8-tall-can': 'xwide',
    '4-tall-can': 'narrow'
  }[featureImageType || '12-short-can']
  

  useLayoutEffect(() => {
    const { top, bottom } = ref.current.getBoundingClientRect()
    const height = top - bottom
    setDistance([0, Math.abs(height)])
  }, [])

  return (
    <HeroMixPackImage className={className} theme={theme} ref={ref} size={size}>
        <div className='inner'>
            <ParallaxItem
                config={[
                {
                    prop: 'y',
                    fromTo: ['0%', '25%'],
                    distance,
                },
                ]}
                style={{ height: '100%' }}
            >
                <Image source={image} objectPosition="50% 100%"  />
            </ParallaxItem>
      </div>
    </HeroMixPackImage>
  )
}
