import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// TODO: add application/ld+json (view source site & https://www.gatsbyjs.org/docs/seo/#generate-rich-snippets-in-search-engines-using-structured-data)
const SEO = ({ seo = {} } = {}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          title: defaultTitle,
          siteName,
          description: defaultDescription,
          siteUrl,
          image,
          locale,
          type,
          twitterCard,
          twitterUser,
        },
      },
    }) => {
      const {
        title,
        metaDesc,
        metaRobotsNofollow,
        metaRobotsNoindex,
        opengraphDescription,
        opengraphImage,
        opengraphTitle,
        opengraphType,
        twitterImage,
        twitterTitle,
        twitterDescription,
        path,
      } = seo

      const imageObject =
        opengraphImage && opengraphImage.localFile
          ? opengraphImage.localFile.childImageSharp.fixed
          : null
      const twitterImageUrl =
        twitterImage && twitterImage.localFile
          ? twitterImage.localFile.childImageSharp.fixed.src
          : null
      const meta = {
        title: title || defaultTitle,
        description: metaDesc || defaultDescription,
        image: `${siteUrl}${imageObject ? imageObject.src : image}`,
        url: `${siteUrl}${path || '/'}`,
        robots: [metaRobotsNoindex, metaRobotsNofollow].join(', '),
        twitterCard,
        locale,
      }

      return (
        <>
          <Helmet title={meta.title}>
            <meta name="description" content={meta.description} />
            <meta name="image" content={meta.image} />
            {meta.url && <link rel="canonical" href={meta.url} />}
            {meta.url && <meta property="og:url" content={meta.url} />}
            {meta.locale && <meta property="og:locale" content={meta.locale} />}
            {(opengraphType || type) && (
              <meta property="og:type" content={opengraphType || type} />
            )}
            {(opengraphTitle || meta.title) && (
              <meta
                property="og:title"
                content={opengraphTitle || meta.title}
              />
            )}
            {(opengraphDescription || meta.description) && (
              <meta
                property="og:description"
                content={opengraphDescription || meta.description}
              />
            )}
            {meta.robots && <meta name="robots" content={meta.robots} />}
            {siteName && <meta property="og:site_name" content={siteName} />}
            {meta.image && <meta property="og:image" content={meta.image} />}
            {imageObject && (
              <meta property="og:image:height" content={imageObject.height} />
            )}
            {imageObject && (
              <meta property="og:image:width" content={imageObject.width} />
            )}
            <meta name="twitter:card" content={meta.twitterCard} />
            {twitterUser && (
              <meta name="twitter:creator" content={`@${twitterUser}`} />
            )}
            {twitterUser && (
              <meta name="twitter:site" content={`@${twitterUser}`} />
            )}
            {(twitterTitle || meta.title) && (
              <meta name="twitter:title" content={twitterTitle || meta.title} />
            )}
            {(twitterDescription || meta.description) && (
              <meta
                name="twitter:description"
                content={twitterDescription || meta.description}
              />
            )}
            {(twitterImageUrl || meta.image) && (
              <meta
                name="twitter:image"
                content={twitterImageUrl || meta.image}
              />
            )}
          </Helmet>
        </>
      )
    }}
  />
)

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteName
        description
        siteUrl
        image
        locale
        type
        twitterCard
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  article: PropTypes.bool,
  locale: PropTypes.string,
  facebookTitle: PropTypes.string,
  facebookDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.object,
  social: PropTypes.shape({
    twitterUser: PropTypes.string,
    twitterCard: PropTypes.string,
    image: PropTypes.object,
  }),
}

export default SEO
