import React from 'react'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'

export const Image = styled.figure``

export default ({
  mediaType,
  source,
  aspectRatio: initialAspectRatio,
  altText,
  className = '',
  objectFit = 'cover',
  objectPosition = '50% 50%',
  ...other
}) => {
  if (!source || !source.localFile) return false

  if (source.localFile.extension === 'svg') {
    return (
      <img
        className={className}
        src={source.localFile.publicURL}
        alt={altText}
      />
    )
  }

  const image = source.localFile.childImageSharp
  const imageData = image[Object.keys(image)[0]]
  const aspectRatio = initialAspectRatio || imageData.aspectRatio

  const classes = [
    className,
    'image',
    aspectRatio < 1 ? 'image--portrait' : 'image--landscape',
    aspectRatio > 2 ? ' image--short-long' : '',
  ].join(' ')

  return (
    <Image className={classes} mediaType={mediaType}>
      <GatsbyImage
        {...image}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={altText || source.altText}
        {...other}
      />
    </Image>
  )
}
