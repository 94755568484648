import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Image from '@elements/Image'
import Swiper from 'react-id-swiper'
import Caption from '@elements/Caption'
import Arrow from '@svgs/Arrow'
import { Navigation } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.min.css'
import { ThemeContext } from '@layout/Layout'
import { MagicItem } from '@hooks/useMagic'

export const Slider = styled.div`
  width: 100%;
  overflow: hidden;

  ${styledMap('isFlex', {
    collapse: css``,
    true: css`
      ${box({
        spacing: 'y',
        multiplier: 0.5,
        type: 'margin',
      })};
      ${box({
        spacing: 'x',
      })};
    `,
    default: css`
      ${box({
        spacing: 'y',
        type: 'margin',
      })};
      ${box({
        spacing: 'x',
      })};
    `,
  })};

  .inner,
  .swiper-container {
    overflow: visible;
  }

  .inner {
    max-width: ${tokens.get('maxWidth.base')};
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('l')`
      max-width: ${tokens.get('maxWidth.wide')};
    `}
  }

  .swiper-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .swiper-wrapper {
    order: 3;
  }

  .slide {
    cursor: move;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .slider-navigation-button {
    border-radius: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: ${spacing(6)};
    width: ${spacing(6)};
    margin-bottom: ${spacing(2)};
    cursor: pointer;
    transition: background-color 100ms linear;
    background-color: ${styledMap('theme', {
      dark: tokens.color('button.base.dark.background'),
      default: tokens.color('button.base.light.background'),
    })};

    .arrow {
      width: ${spacing(2.5)};
    }

    &.next {
      order: 2;
    }

    &.prev {
      order: 1;
      margin-right: ${spacing(2)};

      .arrow {
        transform: scaleX(-1);
      }
    }

    &:hover {
      background-color: ${styledMap('theme', {
        dark: tokens.color('button.base.dark.backgroundHover'),
        default: tokens.color('button.base.light.backgroundHover'),
      })};
    }
  }

  .modal & .slide {
    max-width: 100%;
  }
`

export const Slide = styled.div`
  width: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    > div {
      ${media.lessThan('s')`
        padding-bottom: 100% !important;
      `}
    }
  }

  ${media.greaterThan('s')`
    width: ${(props) => {
      return `${props.ratio * 32}rem`
    }};
    max-width: 100%;
  `}

  ${media.greaterThan('l')`
    width: ${(props) => {
      return `${props.ratio * 42}rem`
    }};
    max-width: 100%;
  `}
`

export default ({ sliderImages, theme: themeOverride, isFlex }) => {
  const { theme } = useContext(ThemeContext)
  const params = {
    modules: [Navigation],
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.slider-navigation-button.next',
      prevEl: '.slider-navigation-button.prev',
    },
    spaceBetween: 8,
    simulateTouch: true,
    autoHeight: true,
    breakpoints: {
      601: {
        spaceBetween: 24,
      },
    },
    renderPrevButton: () => (
      <div className="slider-navigation-button prev">
        <Arrow className="arrow" theme={theme} />
      </div>
    ),
    renderNextButton: () => (
      <div className="slider-navigation-button next">
        <Arrow className="arrow" theme={theme} />
      </div>
    ),
  }

  return (
    <Slider isFlex={isFlex} theme={themeOverride || theme}>
      <div className="inner">
        <Swiper {...params}>
          {sliderImages &&
            sliderImages.map((image, index) => {
              const ratio = image.localFile.childImageSharp.fluid.aspectRatio
              return (
                <Slide
                  ratio={ratio}
                  orientation={ratio <= 1 ? 'portrait' : 'landscape'}
                  className="slide"
                  key={index}
                >
                  <Image source={image} />

                  {image.caption && (
                    <Caption
                      content={image.caption}
                      align="left"
                      theme={themeOverride || theme}
                    />
                  )}
                </Slide>
              )
            })}
        </Swiper>
      </div>
    </Slider>
  )
}
